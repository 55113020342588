/* eslint-disable import/prefer-default-export */
export const LABEL_COLORS = [
  { backgroundColor: 'rgb(231, 231, 231)', color: 'rgb(70, 70, 70)' },
  { backgroundColor: 'rgb(182, 207, 245)', color: 'rgb(13, 52, 114)' },
  { backgroundColor: 'rgb(152, 215, 228)', color: 'rgb(13, 59, 68)' },
  { backgroundColor: 'rgb(227, 215, 255)', color: 'rgb(61, 24, 142)' },
  { backgroundColor: 'rgb(251, 211, 224)', color: 'rgb(113, 26, 54)' },
  { backgroundColor: 'rgb(242, 178, 168)', color: 'rgb(138, 28, 10)' },

  { backgroundColor: 'rgb(194, 194, 194)', color: 'rgb(255, 255, 255)' },
  { backgroundColor: 'rgb(73, 134, 231)', color: 'rgb(255, 255, 255)' },
  { backgroundColor: 'rgb(45, 162, 187)', color: 'rgb(255, 255, 255)' },
  { backgroundColor: 'rgb(185, 154, 255)', color: 'rgb(255, 255, 255)' },
  { backgroundColor: 'rgb(246, 145, 178)', color: 'rgb(153, 74, 100)' },
  { backgroundColor: 'rgb(251, 76, 47)', color: 'rgb(255, 255, 255)' },

  { backgroundColor: 'rgb(255, 200, 175)', color: 'rgb(122, 46, 11)' },
  { backgroundColor: 'rgb(255, 222, 181)', color: 'rgb(122, 71, 6)' },
  { backgroundColor: 'rgb(251, 233, 131)', color: 'rgb(89, 76, 5)' },
  { backgroundColor: 'rgb(253, 237, 193)', color: 'rgb(104, 78, 7)' },
  { backgroundColor: 'rgb(179, 239, 211)', color: 'rgb(11, 79, 48)' },
  { backgroundColor: 'rgb(162, 220, 193)', color: 'rgb(4, 80, 46)' },

  { backgroundColor: 'rgb(255, 117, 55)', color: 'rgb(255, 255, 255)' },
  { backgroundColor: 'rgb(255, 173, 70)', color: 'rgb(255, 255, 255)' },
  { backgroundColor: 'rgb(235, 219, 222)', color: 'rgb(102, 46, 55)' },
  { backgroundColor: 'rgb(204, 166, 172)', color: 'rgb(255, 255, 255)' },
  { backgroundColor: 'rgb(66, 214, 146)', color: 'rgb(9, 66, 40)' },
  { backgroundColor: 'rgb(22, 167, 101)', color: 'rgb(255, 255, 255)' },

  { backgroundColor: 'rgb(7, 130, 197)', color: 'rgb(255, 255, 255)' },
  { backgroundColor: 'rgb(7, 130, 197)', color: 'rgb(247, 151, 28)' },
  { backgroundColor: 'rgb(25, 25, 112)', color: 'rgb(255, 255, 255)' },
  { backgroundColor: 'rgb(17, 44, 62)', color: 'rgb(255, 255, 255)' },
  { backgroundColor: 'rgb(13, 17, 23)', color: 'rgb(201, 209, 217)' },
  { backgroundColor: 'rgb(34, 99, 190)', color: 'rgb(255, 255, 255)' },

  { backgroundColor: 'rgb(0, 0, 0)', color: 'rgb(255, 255, 255)' },
  { backgroundColor: 'rgb(67, 67, 67)', color: 'rgb(255, 255, 255)' },
  { backgroundColor: 'rgb(102, 102, 102)', color: 'rgb(255, 255, 255)' },
  { backgroundColor: 'rgb(153, 153, 153)', color: 'rgb(255, 255, 255)' },
  { backgroundColor: 'rgb(204, 204, 204)', color: 'rgb(0, 0, 0)' },
  { backgroundColor: 'rgb(239, 239, 239)', color: 'rgb(0, 0, 0)' },
  { backgroundColor: 'rgb(243, 243, 243)', color: 'rgb(0, 0, 0)' },
  { backgroundColor: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)' },

  { backgroundColor: 'rgb(251, 76, 47)', color: 'rgb(130, 33, 17)' },
  { backgroundColor: 'rgb(255, 173, 71)', color: 'rgb(164, 106, 33)' },
  { backgroundColor: 'rgb(250, 209, 101)', color: 'rgb(170, 136, 49)' },
  { backgroundColor: 'rgb(22, 167, 102)', color: 'rgb(7, 98, 57)' },
  { backgroundColor: 'rgb(67, 214, 146)', color: 'rgb(26, 118, 77)' },
  { backgroundColor: 'rgb(74, 134, 232)', color: 'rgb(28, 69, 135)' },
  { backgroundColor: 'rgb(164, 121, 226)', color: 'rgb(65, 35, 109)' },
  { backgroundColor: 'rgb(246, 145, 179)', color: 'rgb(131, 51, 76)' },

  { backgroundColor: 'rgb(246, 197, 190)', color: 'rgb(130, 33, 17)' },
  { backgroundColor: 'rgb(255, 230, 199)', color: 'rgb(164, 106, 33)' },
  { backgroundColor: 'rgb(254, 241, 209)', color: 'rgb(170, 136, 49)' },
  { backgroundColor: 'rgb(185, 228, 208)', color: 'rgb(7, 98, 57)' },
  { backgroundColor: 'rgb(198, 243, 222)', color: 'rgb(26, 118, 77)' },
  { backgroundColor: 'rgb(201, 218, 248)', color: 'rgb(28, 69, 135)' },
  { backgroundColor: 'rgb(228, 215, 245)', color: 'rgb(65, 35, 109)' },
  { backgroundColor: 'rgb(252, 222, 232)', color: 'rgb(131, 51, 76)' },

  { backgroundColor: 'rgb(239, 160, 147)', color: 'rgb(130, 33, 17)' },
  { backgroundColor: 'rgb(255, 214, 162)', color: 'rgb(164, 106, 33)' },
  { backgroundColor: 'rgb(252, 232, 179)', color: 'rgb(170, 136, 49)' },
  { backgroundColor: 'rgb(137, 211, 178)', color: 'rgb(7, 98, 57)' },
  { backgroundColor: 'rgb(160, 234, 201)', color: 'rgb(26, 118, 77)' },
  { backgroundColor: 'rgb(164, 194, 244)', color: 'rgb(28, 69, 135)' },
  { backgroundColor: 'rgb(208, 188, 241)', color: 'rgb(65, 35, 109)' },
  { backgroundColor: 'rgb(251, 200, 217)', color: 'rgb(131, 51, 76)' },

  { backgroundColor: 'rgb(230, 101, 80)', color: 'rgb(130, 33, 17)' },
  { backgroundColor: 'rgb(255, 188, 107)', color: 'rgb(164, 106, 33)' },
  { backgroundColor: 'rgb(252, 218, 131)', color: 'rgb(170, 136, 49)' },
  { backgroundColor: 'rgb(68, 185, 132)', color: 'rgb(7, 98, 57)' },
  { backgroundColor: 'rgb(104, 223, 169)', color: 'rgb(26, 118, 77)' },
  { backgroundColor: 'rgb(109, 158, 235)', color: 'rgb(28, 69, 135)' },
  { backgroundColor: 'rgb(182, 148, 232)', color: 'rgb(65, 35, 109)' },
  { backgroundColor: 'rgb(247, 167, 192)', color: 'rgb(131, 51, 76)' },

  { backgroundColor: 'rgb(204, 58, 33)', color: 'rgb(246, 197, 190)' },
  { backgroundColor: 'rgb(234, 160, 65)', color: 'rgb(255, 230, 199)' },
  { backgroundColor: 'rgb(242, 201, 96)', color: 'rgb(254, 241, 209)' },
  { backgroundColor: 'rgb(20, 158, 96)', color: 'rgb(185, 228, 208)' },
  { backgroundColor: 'rgb(61, 199, 137)', color: 'rgb(198, 243, 222)' },
  { backgroundColor: 'rgb(60, 120, 216)', color: 'rgb(201, 218, 248)' },
  { backgroundColor: 'rgb(142, 99, 206)', color: 'rgb(228, 215, 245)' },
  { backgroundColor: 'rgb(224, 119, 152)', color: 'rgb(252, 222, 232)' },

  { backgroundColor: 'rgb(172, 43, 22)', color: 'rgb(246, 197, 190)' },
  { backgroundColor: 'rgb(207, 137, 51)', color: 'rgb(255, 230, 199)' },
  { backgroundColor: 'rgb(213, 174, 73)', color: 'rgb(254, 241, 209)' },
  { backgroundColor: 'rgb(11, 128, 75)', color: 'rgb(185, 228, 208)' },
  { backgroundColor: 'rgb(42, 156, 104)', color: 'rgb(198, 243, 222)' },
  { backgroundColor: 'rgb(40, 91, 172)', color: 'rgb(201, 218, 248)' },
  { backgroundColor: 'rgb(101, 62, 155)', color: 'rgb(228, 215, 245)' },
  { backgroundColor: 'rgb(182, 87, 117)', color: 'rgb(252, 222, 232)' },

  { backgroundColor: 'rgb(130, 33, 17)', color: 'rgb(246, 197, 190)' },
  { backgroundColor: 'rgb(164, 106, 33)', color: 'rgb(255, 230, 199)' },
  { backgroundColor: 'rgb(170, 136, 49)', color: 'rgb(254, 241, 209)' },
  { backgroundColor: 'rgb(7, 98, 57)', color: 'rgb(185, 228, 208)' },
  { backgroundColor: 'rgb(26, 118, 77)', color: 'rgb(198, 243, 222)' },
  { backgroundColor: 'rgb(28, 69, 135)', color: 'rgb(201, 218, 248)' },
  { backgroundColor: 'rgb(65, 35, 109)', color: 'rgb(228, 215, 245)' },
  { backgroundColor: 'rgb(131, 51, 76)', color: 'rgb(252, 222, 232)' },
];
